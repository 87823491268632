import { CORE_NAVIGATION_ITEMS } from "../core.definition";

export class NavigationContext {
   private constructor(
      public featureNavigationItemId?: string,
      public settingsNavigationItemId?: string
   ) { }

   public static forFeatureLink(mainNavigationItemId?: string): NavigationContext {
      return new NavigationContext(mainNavigationItemId);
   }

   public static forSettingsLink(settingsNavigationItemId?: string): NavigationContext {
      return new NavigationContext(CORE_NAVIGATION_ITEMS.SETTINGS, settingsNavigationItemId);
   }
}